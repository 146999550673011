import React from 'react';

export default function VideoLogo() {
  return (
    <>
    <style>
    {
      `.fooDiv {
          width: 100%;
        }`
    }
  </style>
    <img src="https://i.imgur.com/ORynPKf.png" className="fooDiv"></img>
    </>
  );
}
